import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};
//解决vue路由重复导航错误

Vue.use(VueRouter);

const routes = [
	{
		path: "/login",
		name: "login",
		component: () => import("../views/login.vue"),
	},
	{
		path: "/",
		name: "home",
		redirect: "/search",
		component: () => import("../views/index.vue"),
		children: [
			{
				path: "/search",
				name: "search",
				redirect: "/index",
				component: () => import("../views/search/search.vue"),
				children: [
					{
						path: "/index",
						name: "index",
						component: () => import("../views/search/index.vue"),
					},
					{
						path: "/all",
						name: "all",
						component: () => import("../views/search/search_all.vue"),
					},
				],
			},
			{
				path: "/video",
				name: "video",
				component: () => import("../views/video_details.vue"),
			},
			{
				path: "/word",
				name: "word",
				component: () => import("../views/word_details.vue"),
			},
			{
				path: "/audio",
				name: "audio",
				component: () => import("../views/audio_details.vue"),
			},
			{
				path: "/user",
				name: "user",
				component: () => import("../views/user/user.vue"),
			},
			{
				path: "/userdetails",
				name: "userdetails",
				component: () => import("../views/user_details.vue"),
			},
			{
				path: "/videomore",
				name: "videomore",
				component: () => import("../views/more/video_more.vue"),
			},
			{
				path: "/audiomore",
				name: "audiomore",
				component: () => import("../views/more/audio_more.vue"),
			},
			{
				path: "/wordmore",
				name: "wordmore",
				component: () => import("../views/more/word_more.vue"),
			},
		],
	},

	// {
	//   path: '/about',
	//   name: 'about',
	//   component: () => import('../views/AboutView.vue')
	// }
];

const router = new VueRouter({
	// mode: "history",
	base: process.env.BASE_URL,
	routes,
});
router.beforeEach((to, from, next) => {
	if (to.name != "login") {
		if (!store.state.user_id) {
			router.push({
				path: "/login",
			});
			return;
		}
		next();
	}
	next(); //放行路由
});
router.afterEach((to, from) => {
	let bodySrcollTop = document.body.scrollTop;
	if (bodySrcollTop !== 0) {
		document.body.scrollTop = 0;
		return;
	}
	let docSrcollTop = document.documentElement.scrollTop;
	if (docSrcollTop !== 0) {
		document.documentElement.scrollTop = 0;
	}
});
export default router;
