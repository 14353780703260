import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
Vue.use(Vuex);

import { getUserInfo, getConfig, getweidu } from "@/service/user_api";
import { searchList } from "@/service/search_api";
import {
	Spin
} from "view-design";
const vuexLocal = new VuexPersist({
	storage: window.sessionStorage, // 可选存储方式
});

export default new Vuex.Store({
	state: {
		user_id: "",
		user_info: "",
		data_all: {},
		video_ary: {},
		audio_ary: {},
		word_ary: {},
		config: {},
		weidu: 0//未读消息
	},
	getters: {},
	mutations: {
		set_user_id(state, val) {
			state.user_id = val;
		},
		set_userInfo(state, obj) {
			state.user_info = obj;
		},
		set_data_all(state, obj) {
			state.user_info = obj;
		},
		set_data_ary(state, obj) {
			console.log(obj);
			if (obj.type == 1) {
				state.video_ary = obj;
			}
			if (obj.type == 2) {
				state.audio_ary = obj;
			}
			if (obj.type == 3) {
				state.word_ary = obj;
			}
		},
		set_data_config(state, obj) {
			state.config = obj;
		},
		set_weidu(state, data) {
			state.weidu = data;
		},
	},
	actions: {
		// 全局
		get_config(store) {
			Spin.show();
			getConfig().then((res) => {
				Spin.hide();
				if (res.code == 0) {
					store.commit("set_data_config", res.data);
					document.title = res.data.web_title;
				}
			});
		},
		get_userInfo(store) {
			let state = store.state;
			if (!state.user_id) return;
			Spin.show();
			getUserInfo({
				user_id: state.user_id,
			}).then((res) => {
				Spin.hide();
				console.log(res);
				store.commit("set_userInfo", res.data);
			});
		},
		async get_data_index(store, obj) {
			let state = store.state;
			Spin.show();
			try {
				const promises = [1, 2, 3].map(resource_type => {
					return searchList({
						user_id: state.user_id,
						resource_type: resource_type,
						...obj,
					});
				});
				const results = await Promise.all(promises);
				results.forEach((res, index) => {
					if (res) {
						store.commit("set_data_ary", {
							type: index + 1,
							data: {
								data: res.data,
								count: res.count,
							},
						});
					} else {
						store.commit("set_data_ary", {
							type: index + 1,
							data: {},
						});
					}
				});
			} catch (error) {
				console.error(error);
			} finally {
				Spin.hide();
			}
		},
		// 获取未读消息
		get_weidu(store) {
			let state = store.state;
			if (!state.user_id) return;
			getweidu().then(res => {
				if (res.code == 0) {
					store.commit("set_weidu", res.data.weidu_count);
				}
			})
		}
	},
	modules: {},
	plugins: [vuexLocal.plugin],
});
