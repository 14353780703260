<!--  -->
<template>
	<div class="footer">
		<p>
			{{ configs.web_footer }}
		</p>
		<a :href="configs.beianhaolianjie">{{ configs.beianhao }}</a>
	</div>
</template>

<script>
export default {
	components: {},
	data() {
		return {};
	},
	created() {},
	computed: {
		configs() {
			return this.$store.state.config;
		},
	},
};
</script>

<style lang="scss" scoped>
.footer {
	width: 100%;
	height: 120px;
	line-height: 120px;
	text-align: center;
	background-color: white;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	a{
		color: #6c6c6c;
		margin-left: 20px;
	}
	p {
		color: #6c6c6c;
	}
}
</style>
