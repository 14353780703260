import instance from "./index";
import store from "./../store";
import { Message } from "view-design";
// let user_id = store.state.user_id;

// instance.get("//api/login/register")
export function registerUser(obj) {
	Object.keys(obj).forEach((item) => {
		console.log(obj[item]);
	});
	console.log(obj);
	let instan = instance.post("/api/login/register", obj);
	return instan;
}
export function loginUser(obj) {
	let instan = instance.post("/api/login/login", obj);
	return instan;
}

export function getUserInfo(user_id) {
	let instan = instance.post("/api/user/getUserinfo", user_id);
	return instan;
}

export function getSoundList(obj) {
	let user_id = store.state.user_id;
	let instan = instance.post("/api/sound/list", {
		user_id,
		...obj,
	});
	return instan;
}
// 获取配置
export function getConfig() {
	let instan = instance.get("/api/config/get");
	return instan;
}
// 获取未读消息数量
export function getweidu() {
	let user_id = store.state.user_id;
	let instan = instance.post("/api/comment/get_weidu_xiaoxi_count",{
		user_id,
	});
	return instan;
}
// 获取未读消息内容
export function getweiduContent() {
	let user_id = store.state.user_id;
	let instan = instance.post("/api/comment/get_shouye_pinglun_list",{
		user_id,
	});
	return instan;
}