import axios from "axios";
import router from "@/router";
import store from "@/store";
import { Message, Spin } from "view-design";

const baseURL = "https://server.interpretingresources.net";
const instance = axios.create({
	baseURL,
	timeout: 1000000,
	headers: {
		"Content-Type": "multipart/form-data",
	},
});

// 创建一个取消令牌源
const CancelTokenSource = axios.CancelToken.source();

instance.interceptors.request.use(
	function (config) {
		// 将取消令牌添加到请求配置中
		config.cancelToken = CancelTokenSource.token;
		// Spin.show();
		return config;
	},
	function (error) {
		// Spin.hide();
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	function (response) {
		// Spin.hide();
		

		return response.data;
	},
	function (error) {
		return Promise.reject(error);
	}
);

export default instance;
