import instance from "./index";
import store from "./../store";
export function searchList(obj) {
	let user_id = store.state.user_id;
	let instan = instance.post("/api/resource/list", {
		user_id,
		...obj,
	});
	return instan;
}
