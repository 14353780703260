<template>
	<div id="app">
		<router-view />
		<footer_v1 />
	</div>
</template>
<script>
import footer_v1 from "./components/footer.vue";
export default {
	components: {
		footer_v1,
	},
	created() {
		this.$store.dispatch("get_config");
	},
};
</script>
<style lang="scss">
@import "./style/index.css";
@import "./style/all.scss";
#app {
	min-width: 1200px;
}
</style>
